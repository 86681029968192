import * as types from './mutation_types';

export default {
  [types.ATTEND_LIST_RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      list: [],
      itemsById: {}
    });
  },

  [types.ATTEND_LIST_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.ATTEND_LIST_ADD_RETRIEVED](state, retrieved) {
    Object.assign(state, {
      list: [ ...state.list, retrieved ],
      itemsById: { ...state.itemsById, [retrieved['@id']]: retrieved }
    });
  },

  [types.ATTEND_LIST_TOGGLE_LOADING](state) {
    Object.assign(state, { error: '', isLoading: !state.isLoading });
  },

  [types.ATTEND_LIST_ADD_COMPLETION](state, { attend, lesson }) {
    const map = { ...state.itemsById };
    map[attend]['completions'] = [ ...map[attend]['completions'], lesson];

    Object.assign(state, { itemsById: map });
  },

  [types.ATTEND_LIST_REMOVE_COMPLETION](state, { attend, lesson }) {
    const map = { ...state.itemsById };
    map[attend]['completions'] = [ ...map[attend]['completions'].filter(l => l !== lesson) ];

    Object.assign(state, { itemsById: map });
  },
};

import SubmissionError from '../../../../error/SubmissionError';
import fetch from '../../../../utils/fetch';
import * as types from './mutation_types';
import { ATTEND_LIST_REMOVE_COMPLETION } from "../../attend/list/mutation_types";

export const create = ({ commit }, { attend, lesson }) => {
  commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_SET_ERROR, '');
  commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_TOGGLE_LOADING);

  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');

  return fetch('/lessons/uncomplete', { method: 'POST', headers: headers, body: JSON.stringify({ attend, lesson }) })
    .then((response) => {
      commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_SET_CREATED, data);
      commit('attend/list/' + ATTEND_LIST_REMOVE_COMPLETION, { attend, lesson }, { root: true });
    })
    .catch((e) => {
      commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_TOGGLE_LOADING);

      if (e instanceof SubmissionError) {
        commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_SET_VIOLATIONS, e.errors);
        // eslint-disable-next-line
        commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_SET_ERROR, e.errors._error);

        return;
      }

      commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.LESSONUNCOMPLETIONREQUEST_CREATE_RESET);
};
